import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-multi-date-picker';
import Select from 'react-select';
import { setContact } from '../reducers/contact';
import { StyledContactInfo } from './styles';
import { BottomButtons, ReturnButton, ContinueButton } from './styles/buttons';

const options = (array) => {
  return array.map((item) => {return {value: item, label:item}});
};

const hourOptions = options([11, 12, 13, 14, 15, 16, 17, 18]);
const minuteOptions = options(["00", 10, 20, 30, 40, 50]);

const ContactInfo = () => {
  const dispatch = useDispatch();
  const { order, contact } = useSelector((state) => state);
  const navigate = useNavigate();

  const validMenus = order.menus.filter((item) => item.quantity > 0);
  const validBuffet = order.buffet.filter((item) => item.quantity > 0);

  useEffect(() => {
    if (validMenus.length === 0 && validBuffet.length === 0) {
      navigate('/');
    };
  }, [order]);

  const checked = (contact.name
    && contact.email
    && contact.phone
    && contact.date)
    && (validMenus.length > 0 || validBuffet.length > 0);

  return(
    <StyledContactInfo>
      <div id='contactInfoTitle'>Kontaktinfo</div>
      <div id='contactInfoContent'>
        <div className='inputGroup'>
          <label htmlFor='name'>Navn:</label>
          <input
            id='name'
            type='text'
            value={contact.name}
            required={true}
            onChange={(e) => dispatch(setContact({ ...contact, name:e.target.value }))}
          />
        </div>

        <div className='inputGroup'>
          <label htmlFor='email'>E-post:</label>
          <input
            id='email'
            type='email'
            value={contact.email}
            required={true}
            onChange={(e) => dispatch(setContact({ ...contact, email:e.target.value }))}
          />
        </div>

        <div className='inputGroup'>
          <label htmlFor='phone'>Telefonnummer:</label>
          <input
            id='phone'
            type='text'
            value={contact.phone}
            required={true}
            onChange={(e) => dispatch(setContact({ ...contact, phone:e.target.value }))}
          />
        </div>

        <div className='inputGroup'>
          <label htmlFor='datePicker'>Hentetid:</label>
          <div className='dateGroup'>
            <DatePicker
              id='datePicker'
              mapDays={({ date }) => {
                let isWeekend = [0].includes(date.weekDay.index);

                if (isWeekend) return {
                  disabled: true,
                  style: { color: '#cccccc'}
                }
              }}
              value={contact.date}
              format="YYYY-MM-DD"
              minDate={new Date().toISOString().split('T')[0]}
              weekStartDayIndex={1}
              editable={false}
              required={true}
              onChange={(e) => dispatch(setContact({ ...contact, date: e.toLocaleString() }))}
            />
            <Select
              className='react-select'
              classNamePrefix='react-select'
              options={hourOptions}
              defaultValue={{value: contact.hour, label: contact.hour}}
              onChange={(e) => dispatch(setContact({ ...contact, hour: e.value }))}
            />
            <Select
              className='react-select'
              classNamePrefix='react-select'
              options={minuteOptions}
              defaultValue={{value: contact.minute, label: contact.minute}}
              onChange={(e) => dispatch(setContact({ ...contact, minute: e.value }))}
            />
          </div>
          <div className='warning'>OBS: Helligdager fri</div>
        </div>

        <div className='inputGroup'>
          <label htmlFor='addition'>Ytterligere informasjon:</label>
          <textarea
            id='addition'
            rows={8}
            maxLength={400}
            value={contact.addition}
            onChange={(e) => dispatch(setContact({ ...contact, addition:e.target.value }))}
          />
        </div>
      </div>
      <BottomButtons>
        <ReturnButton onClick={() => navigate('/order')}>Tilbake</ReturnButton>
        <ContinueButton onClick={() => navigate('/order_info')} disabled={!checked}>Fortsette</ContinueButton>
      </BottomButtons>
    </StyledContactInfo>
  );
};

export default ContactInfo;