import styled from 'styled-components';

const StyledModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;

  .modalBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(128, 128, 128, 0.8);
  };

  .modalWindow {
    width: 280px;
    min-height: 400px;
    height: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;

    display: flex;
    flex-direction: column;

    .modalHeader {
      display: flex;
      flex-direction: row;
      justify-content: right;

      .closeButton {
        width: 24px;
        height: 24px;
        border: none;
        background-color: black;
        padding: 0px;
        margin: 0px;
        border-top-right-radius: 8px;
        color: white;
        font-size: 16px;
        font-weight: 600;

        &:hover {
          cursor: pointer;
        };
      };
    };

    .modalBody {
      height: 100%;
      display: flex;
      flex-direction: column;

      .itemImgContainer {
        width: 100%;
        height: 150px;
        padding-bottom: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        img {
          width: 50%;
          object-fit: contain;
          filter: drop-shadow(0px 4px 2px gray);
        };
      };

      .itemDetailContainer {
        display: flex;
        flex-direction: column;
        padding: 8px;

        .itemName {
          font-size: 18px;
          font-weight: 600;
          padding-bottom: 4px;
        };

        .buffetListContainer {
          display: flex;
          flex-direction: column;
          border-top: 2px solid lightgray;
          font-size: 12px;

          .buffetRow {
            display: grid;
            grid-template-columns: 80% 5% 15%;

            >span:last-child {
              text-align: right;
            };
          };
        };

        .allergyList, .ingredientList {
          font-size: 12px;
          color: gray;
          border-top: 1px solid lightgray;
          border-bottom: 1px solid lightgray;
        };

        .ingredientList {
          height: max-content;
          font-size: 10px;
          border-top: none;
          max-height: 60px;
          overflow-y: auto;
        };

        .energyText {
          font-size: 14px;
        };

        .detailRow {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
        };
      };
    };
  };
`;

export default StyledModal;
