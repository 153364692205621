import React, { useState, useEffect } from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { initializeOrder } from './reducers';
import Home from './components/Home';
import Order from './components/Order';
import ContactInfo from './components/ContactInfo';
import OrderInfo from './components/OrderInfo';

const App = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state);

  useEffect(() => {
    dispatch(initializeOrder());
  }, []);

  return (
    <div>
      <BrowserRouter>
        <div className="routes">
          <Routes>
            <Route path="/order_info" element={<OrderInfo />} />
            <Route path="/contact_info" element={<ContactInfo />} />
            <Route path="/order" element={<Order />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;