import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, FieldArray, Field } from 'formik';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import InfoIcon from '@mui/icons-material/Info';
import { modifyBuffet, modifyMenu } from '../reducers/order';
import Modal from './Modal';
import { StyledOrder } from './styles';
import { TopButtons, BottomButtons, ReturnButton, ContinueButton, ConfirmButton } from './styles/buttons';
import Hero from '../public/imgs/Hero.jpg';
import Logo from '../public/imgs/Logo.png';
import Bird from '../public/imgs/Bird.png';
import Sun from '../public/imgs/Sun.png';

const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { order, contact } = useSelector((state) => state);
  const [modalItem, setModalItem] = useState(null);

  const checked = order.menus.reduce((total, next) => total + next.quantity, 0) > 0
    || order.buffet.reduce((total, next) => total + next.quantity, 0) > 0;

  const closeModal = () => {
    setModalItem(null);
  };

  if (order.buffet.length === 0) {
    return null;
  };

  return(
    <StyledOrder>
      {modalItem && <Modal modalItem={modalItem} closeModal={closeModal} />}
      <div className={`content ${modalItem ? 'blur' : ''}`}>
        <div className="header">
          <div className="headerLeft">
            <img src={Logo} alt="Itsudemo logo" />
          </div>
          <div className="headerMiddle">
            <a href="#buffetDiv" className="buffetMenuButton">Buffet</a>
            <a href="#menuDiv" className="buffetMenuButton">Menyer</a>
          </div>
          <div className="headerRight">
            <div className="birdContainer">
              <img src={Bird} />
            </div>
            <div className="sunContainer">
              <img src={Sun} />
            </div>
          </div>
        </div>

        <div className="body">
          <div className="heroContainer">
            <img src={Hero} />
          </div>
          <div className="orderContainer">
            <Formik
              initialValues={order}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <FieldArray name="buffet">
                    <div className="buffetDiv" id="buffetDiv">
                      <div className="title">Buffet</div>
                      <div className="items">
                        {values.buffet.map((item, index) => (
                          <div key={`buffet-${item.id}`} className="item">
                            <div className={`itemImgContainer ${item.quantity > 0 ? 'selected' : ''}`}>
                              <div
                                role="presentation"
                                className="infoIcon"
                                onClick={() => setModalItem(item)}
                              >
                                <InfoIcon />
                              </div>
                              <img src={item.link} alt={`item picture of ${item.name}`} />
                            </div>
                            <div className="itemInfoContainer">
                              <div className="itemInfoLeft">
                                <div className="itemName">{item.name}</div>
                                <div className="itemPrice">≈{item.refPrice}, -per bit</div>
                              </div>
                              <div className="itemInfoRight">
                                <span
                                  className={`minus ${item.quantity === 0 ? 'disable' : ''}`}
                                  onClick={() => {
                                    const newValue = Math.max(item.quantity -1, 0);
                                    dispatch(modifyBuffet({ item, quantity: newValue }));
                                    setFieldValue(`buffet.${index}.quantity`, newValue);
                                  }
                                }
                                >
                                  <IndeterminateCheckBoxIcon />
                                </span>
                                <span>{item.quantity}</span>
                                <span
                                  className="plus"
                                  onClick={() => {
                                    dispatch(modifyBuffet({ item, quantity: item.quantity + 1 }));
                                    setFieldValue(`buffet.${index}.quantity`, item.quantity + 1);
                                  }}
                                >
                                  <AddBoxIcon />
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </FieldArray>
                  <FieldArray name="menu">
                    <div className="menuDiv" id="menuDiv">
                      <div className="title">Menyer</div>
                      <div className="items">
                        {values.menus.map((item, index) => (
                          <div key={`menu-${item.id}`} className="item">
                            <div className={`itemImgContainer ${item.quantity > 0 ? 'selected' : ''}`}>                              <div
                                role="presentation"
                                className="infoIcon"
                                onClick={() => setModalItem(item)}
                              >
                                <InfoIcon />
                              </div>
                              <img src={item.link} alt={`item picture of ${item.name}`} />
                            </div>
                            <div className="itemInfoContainer">
                              <div className="itemInfoLeft">
                                <div className="itemName">{item.name}</div>
                                <div className="itemPrice">{`ca.${item.refPrice}kr`}</div>
                              </div>
                              <div className="itemInfoRight">
                                <span
                                  className={`minus ${item.quantity === 0 ? 'disable' : ''}`}
                                  onClick={() => {
                                    const newValue = Math.max(item.quantity - 1, 0);
                                    dispatch(modifyMenu({ item, quantity: newValue }));
                                    setFieldValue(`menus.${index}.quantity`, newValue);
                                  }}
                                >
                                  <IndeterminateCheckBoxIcon />
                                </span>
                                <span>{item.quantity}</span>
                                <span
                                  className="plus"
                                  onClick={() => {
                                    dispatch(modifyMenu({ item, quantity: item.quantity + 1 }));
                                    setFieldValue(`menus.${index}.quantity`, item.quantity + 1);
                                  }}
                                >
                                  <AddBoxIcon />
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </FieldArray>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="footer">
            <ReturnButton onClick={() => navigate('/')}>Tilbake</ReturnButton>
            <ContinueButton onClick={() => navigate('/contact_info')} disabled={!checked}>Fortsette</ContinueButton>
        </div>
      </div>
    </StyledOrder>
  );
};

export default Order;