import { createSlice } from '@reduxjs/toolkit';
import { getData } from '../services';

const orderSlice = createSlice({
  name: 'order',
  initialState: { buffet: [], menus: [] },
  reducers: {
    setOrder(state, action) {
      return action.payload;
    },
    modifyMenu(state, action) {
      return {
        buffet: state.buffet,
        menus: state.menus
          .map((item) => item.name === action.payload.item.name
            ? {...item, quantity: action.payload.quantity}
            : item)
      };
    },
    modifyBuffet(state, action) {
      return {
        menus: state.menus,
        buffet: state.buffet
          .map((item) => item.name === action.payload.item.name
            ? {...item, quantity: action.payload.quantity}
            : item)
      };
    },
    resetOrder(state, action) {
      return {
        menus: state.menus.map((item) => ({ ...item, quantity: 0 })),
        buffet: state.buffet.map((item) => ({ ...item, quantity: 0 })),
      };
    },
  },
});

export const { setOrder, modifyMenu, modifyBuffet, resetOrder } = orderSlice.actions;

export const initializeOrder = () => async (dispatch) => {
  const data = await getData();
  const buffet = data.buffet.map((item) => ({...item, quantity: 0}));
  const menus = data.menus.map((item) => ({...item, quantity: 0}));
  dispatch(setOrder({ buffet, menus }));
};

export default orderSlice.reducer;
