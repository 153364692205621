import 'core-js/stable/index';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import { createGlobalStyle } from 'styled-components';
import App from './App';

const GlobalStyle = createGlobalStyle`
  html {
    width: 100%;
    height: 100%;
    font-family: 'Roboto Mono', monospace;
    scroll-behavior: smooth;

    body {
      width: 100%;
      min-width: 280px;
      height: 100%;
      margin: 0;
      background-color: #FAFAFA;

      input, textarea {
        font-family: 'Roboto Mono', monospace;
      };
    };
  };
`;

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
    <GlobalStyle />
  </Provider>
);
