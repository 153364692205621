import styled from "styled-components";
import { doneIconAppear } from "./animations";

const StyledDone = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  .center {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -80%);

    flex-direction: column;
    align-items: center;
    text-align: center;

    .checkedIcon {
      color: lightgreen;
      font-size: 100px;
      animation: ${doneIconAppear} 0.5s ease;
    };

    .thankYou {
      font-size: 30px;
      margin-bottom: 50px;
    };

    div:last-child {
      padding: 0 10%;
    };
  };
`;

export default StyledDone;
