import { configureStore } from '@reduxjs/toolkit';
import { orderReducer, contactReduer } from './reducers';

const store = configureStore({
  reducer: {
    order: orderReducer,
    contact: contactReduer,
  },
});

export default store;
