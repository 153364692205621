import React from "react";
import { StyledModal } from "./styles";

const Modal = ({ modalItem, closeModal }) => {
  return (
    <StyledModal>
      <div
        role="presentation"
        className="modalBackground"
        onClick={closeModal}
      />

      <div className="modalWindow">
        <div className="modalHeader">
          <button
            type='button'
            className="closeButton"
            onClick={closeModal}
          >
            X
          </button>
        </div>

        <div className="modalBody">
          <div className="itemImgContainer">
            <img src={modalItem.link} alt="product image" />
          </div>
          <div className="itemDetailContainer">
            <div className="itemName">{modalItem.name}</div>
            {modalItem.buffets && (
              <div className="buffetListContainer">
                {modalItem.buffets.map((buffet) => (
                  <div className="buffetRow" key={buffet.name}>
                    <span>{buffet.name}</span>
                    <span>X</span>
                    <span>{buffet.quantity}</span>
                  </div>
                ))}
              </div>
            )}
            <div className="allergyList">{modalItem.allergy}</div>
            <div className="ingredientList">{modalItem.ingredients}</div>
            {modalItem.buffets && (
              <div className="energyContainer">
                <div className="energyText">Næringsverdier per 100 g</div>
                <div className="detailRow">
                  <span>energi</span>
                  <span>{modalItem.kj} kJ</span>
                </div>
                <div className="detailRow">
                  <span></span>
                  <span>{modalItem.kcal} kcal</span>
                </div>
                <div className="detailRow">
                  <span>fett</span>
                  <span>{modalItem.fat} g</span>
                </div>
                <div className="detailRow">
                  <span>hvorav mettet fett</span>
                  <span>{modalItem.saturatedFat} g</span>
                </div>
                <div className="detailRow">
                  <span>karbohydrater</span>
                  <span>{modalItem.carbs} g</span>
                </div>
                <div className="detailRow">
                  <span>hvorav sukkerarter</span>
                  <span>{modalItem.sugar} g</span>
                </div>
                <div className="detailRow">
                  <span>protein</span>
                  <span>{modalItem.protein} g</span>
                </div>
                <div className="detailRow">
                  <span>salt</span>
                  <span>{modalItem.salt} g</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledModal>
  );
};

export default Modal;
