import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useInView } from 'react-intersection-observer';
import { sendFeedback } from '../services';
import { StyledHome } from './styles';
import { CgCheckO } from 'react-icons/cg';
import Hero from '../public/imgs/Hero.jpg';
import Logo from '../public/imgs/Logo.png';
import Arrow from '../public/imgs/Arrow.png';
import Bird from '../public/imgs/Bird.png';
import Sun from '../public/imgs/Sun.png';
import Order from '../public/imgs/Order.png';
import Contact from '../public/imgs/Contact.png';
import Info from '../public/imgs/Info.png';
import Tree from '../public/imgs/Tree.png';
import MountainSea from '../public/imgs/MountainSea.png';
import MountainBird from '../public/imgs/MountainBird.png';
import FacebookIcon from '../public/imgs/FacebookIcon.png';
import InsIcon from '../public/imgs/InsIcon.png';

const Home = () => {
  const navigate = useNavigate();
  const [section, setSection] = useState('Info');
  const [showMenu, setShowMenu] = useState(false);
  const [sent, setSent] = useState(false); // Feedback form has been sent

  // Approach to detect if an element has entered the view
  const [treeImgRef, treeImgInView, treeImgEntry] = useInView();
  const [textAfterTreeRef, textAfterTreeInView, textAfterTreerEntry] = useInView();
  const [mountainSeaImgRef, mountainSeaImgInView, mountainSeaImgEntry] = useInView();
  const [orderImgDivRef, orderImgDivInView, orderImgDivEntry] = useInView();
  const [mountainBirdImgRef, mountainBirdImgInView, mountainBirdImgEntry] = useInView();
  // End


  const toggleMenu = (targetSection) => {
    setShowMenu(!showMenu);
    if (targetSection) {
      setSection(targetSection);
    };
  };

  const initialValues = {
    name: '',
    email: '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup
      .string()
      .min(2)
      .max(20)
      .required('Name required'),
    email: Yup
      .string()
      .email('Invalid email')
      .required('Email required'),
    message: Yup
      .string()
      .min(10)
      .max(500)
      .required('Message required'),
  });

  const submitForm = async (values, { setSubmitting, resetForm }) => {
    await sendFeedback(values);
    setSent(true); // Display the success icon
    resetForm();
    setTimeout(() => {
      setSent(false); // Display the form again
    }, 3000);
  };

  return (
    <StyledHome>
      <div className="homeTop">
        <div className="navBar">
          <div className="navLeft">
            <div className="logoDiv">
              <img src={Logo} width="100%" />
            </div>
            <div className="menuDiv">
              <div className="menuText">{section}</div>
              <button
                className="arrowButton"
                type="button"
                onClick={() => toggleMenu()}
              >
                <img className={showMenu ? 'up' : 'down'} src={Arrow} width="100%" />
              </button>
            </div>
          </div>
          <div className="navRight">
            <div className="birdDiv">
              <img src={Bird} width="100%" />
            </div>
            <div className="sunDiv">
              <img src={Sun} width="100%" />
            </div>
          </div>
        </div>
        <div className={`menuListDiv ${showMenu ? 'expand' : 'collapse'}`}>
          <a
            className="menuRow"
            href="#info"
            onClick={() => toggleMenu('Info')}
          >
            <div className="menuRowIcon">
              <img src={Info} />
            </div>
            <div className="menuRowText">Info</div>
          </a>
          <a
            className="menuRow"
            href="#order"
            onClick={() => toggleMenu('Bestill')}
          >
            <div className="menuRowIcon">
              <img src={Order} />
            </div>
            <div className="menuRowText">Bestill</div>
          </a>
          <a
            className="menuRow"
            href="#contact"
            onClick={() => toggleMenu('Kontakt')}
          >
            <div className="menuRowIcon">
              <img src={Contact} />
            </div>
            <div className="menuRowText">Kontakt</div>
          </a>
        </div>
      </div>
      <div className="homeBody">
        <div className="breakLine" id="info" />
        <div className="infoDiv">
          <div className="infoUpperDiv">
            <img src={Hero} />
          </div>
          <div className="infoMiddleDiv">
            <div className={`treeImg${treeImgInView ? ' playAnimation' : ''}`} ref={treeImgRef}>
              <img src={Tree} width="100%" />
            </div>
            <div className={`textAfterTree${textAfterTreeInView ? ' playAnimation' : ''}`} ref={textAfterTreeRef}>
              <div>ENJOYMENT</div>
              <div>
                <div>ANYWHERE, </div>
                <div>ANYTIME</div>
              </div>
            </div>
          </div>
          <div className="infoLowerDiv">
            <div className="infoDescription">
              Vi tilbyr en ny stil for sushi-spising
              – hvor og når som helst med tilgjengelige variasjoner og priser.
              Vi vil gjerne presentere en inkluderende opplevelse av å nyte sushi med tilpasset utvalg og unike kombinasjoner.
            </div>
            <div className={`mountainSeaImg${mountainSeaImgInView ? ' playAnimation' : ''}`} ref={mountainSeaImgRef}>
              <img src={MountainSea} />
            </div>
          </div>
        </div>
        <div className="breakLine" id="order" />
        <div className="orderDiv">
          <div className="orderUpperDiv">
            <div className="orderLeftDiv">
              <div className={`orderImgDiv${orderImgDivInView ? ' playAnimation' : ''}`} ref={orderImgDivRef}/>
              <div className="orderTextDiv">
                <div className="orderTextTitle">
                  4-GRADERS-PRINSIPPET
                </div>
                <div className="orderTextBody">
                  Sushi handler om ferskhet, så vår sushi holdes alltid på den ideelle temperaturen 4°C
                </div>
              </div>
            </div>
            <div className="orderRightDiv">
              <div className="orderTextDiv">
                <div className="orderTextTitle">
                  2-TIMERS-PRINSIPPET
                </div>
                <div className="orderTextBody">
                  Vår sushi lages fersk hele dagen, og er aldri mer enn to timer, for å sikre best mulig kvalitet
                </div>
              </div>
              <div className={`orderImgDiv${orderImgDivInView ? ' playAnimation' : ''}`} ref={orderImgDivRef}/>
            </div>
          </div>
          <div className="orderLowerDiv">
            <button
              type="button"
              className="orderButton"
              onClick={() => navigate('/order')}
            >
              Bestill På Nett
            </button>
            <div className={`mountainBirdImg${mountainBirdImgInView ? ' playAnimation' : ''}`} ref={mountainBirdImgRef}>
              <img src={MountainBird} />
            </div>
          </div>
        </div>
        <div className="breakLine" id="contact" />
        <div className="contactDiv">
          <div className="contactUpperDiv">
            <div className="contactTreeImgDiv">
              <img src={Tree} width="100%" />
            </div>
            <div className="contactTextDiv">
              <div className="contactTextTitle">KONTAKT</div>
              <div className="contactTextBody">
                <div>Itsudemo Sushi/Spartao Norway AS</div>
                <div>VAT: 927978865MVA</div>
                <div>E-Post: mega.metro@itsudemo.no</div>
                <div>Kontakt Nummer: +47906306628</div>
                <div>Firma Adresse: 5th floor, 28 Nydalsveien, Oslo 0484</div>
              </div>
            </div>
            <div className="contactMapDiv">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1999.279592044781!2d10.956165952066025!3d59.92750266986962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464164d86de9b02b%3A0xe55799b3198b8aa!2sCoop%20Mega%20Metro!5e0!3m2!1sen!2sfi!4v1665141413935!5m2!1sen!2sfi"
                width="100%"
                height="100%"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              >
              </iframe>
            </div>
          </div>
          <div className="contactFormDiv">
            {sent && <div className="successIconDiv"><CgCheckO className='checkedIcon'/></div>}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitForm}
            >
              {({
                values,
                isValid,
                dirty,
                handleSubmit,
                isSubmitting,
              }) => (
                <form className="feedbackForm" onSubmit={handleSubmit}>
                  <div className="formTitle">TILBAKEMELDING</div>
                  <Field type="text" name="name" placeholder="*NAVN" />
                  <Field type="email" name="email" placeholder="*E-POST" />
                  <Field type="text" name="message" placeholder="*INNHOLD" as="textarea" rows={5} />
                  <button
                    className={`feedbackFormButton ${isSubmitting && 'submitting'}`}
                    type="submit"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    <div>{isSubmitting ? '' : 'SENDE'}</div>
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div className="footer">
          <div>
            <a href="https://www.facebook.com/itsudemosushibar/" target="_blank">
              <img src={FacebookIcon} width="100%" />
            </a>
          </div>
          <div>
            <img src={Logo} width="100%" />
          </div>
          <div>
            <a href="https://www.instagram.com/itsudemo_sushi/?hl=en" target="_blank">
              <img src={InsIcon} width="100%" />
            </a>
          </div>
        </div>
      </div>
    </StyledHome>
  )
};

export default Home;