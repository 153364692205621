import styled from "styled-components";

const BottomButtons = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  background-color: #FAFAFA;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 2.5%;
  bottom: 0px;
`;

const TopButtons = styled(BottomButtons)`
  top: 0px;
`;

const button = styled.button`
  color: #FFFFFF;
  padding: 10px;
  width: 40%;
  max-width: 160px;
  border: none;
  border-radius: 10px;
  background: lightgrey;

  &:not([disabled]) {
    box-shadow: 4px 4px 8px #888888;
    transition: box-shadow .5s;

    &:hover {
      cursor: pointer;
      box-shadow:  2px 2px 4px #888888;
      transition: box-shadow .5s;
    };
  };
`;

const ReturnButton = styled(button)`
  background: #888888;
`;

const ContinueButton = styled(button)`
  &:not([disabled]) {
    background: #0171FF;
  };
`;

const ConfirmButton = styled(button)`
  &:not([disabled]) {
    background: #F43D77;
  };

  &.selected {
    box-shadow: none;
  };
`;

export {
  TopButtons,
  BottomButtons,
  ReturnButton,
  ContinueButton,
  ConfirmButton,
};
