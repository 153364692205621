import styled from "styled-components";

const StyledOrderInfo = styled.div`
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2.5%;

  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  .orderInfoContact {
    width: 100%;
    border-radius: 8px;
    background-color: #FCFCFC;
    box-shadow: 4px 4px 8px #d7d7d7;
    display: flex;
    flex-direction: column;

    font-size: 16px;
    padding: 8px;
    gap: 10px;
    text-align: center;
    word-break: break-all;
  };

  .orderInfoProducts {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 52px;

    @keyframes fromScale {
      from {
        opacity: 0;
        transform: scale(0);
      }
    };

    animation: fromScale .5s ease;

    .orderInfoItemContainer {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      background-color: #FCFCFC;
      box-shadow: 4px 4px 8px #d7d7d7;
      padding: 5px;
      margin-top: 20px;

      .orderInfoItemTitle {
        width: 100%;
        text-align: center;
        margin: auto;
        font-size: 20px;
        border-bottom: 2px solid lightgrey;
      };

      .orderInfoProductsItems {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 10px;
        box-sizing: border-box;
        border-bottom: 2px solid lightgrey;

        .item {
          width: 150px;
          height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          border-radius: 8px;
          background-color: #FFFFFF;
          box-shadow: 6px 6px 6px #CACACA;

          .itemName {
            margin-top: 5px;
            text-align: center;
            font-size: 14px;
          }

          .itemPic {
            width: 80px;
            height: fit-content;

            img {
              max-width: 100%;
              max-height: 100%;
              filter: drop-shadow(2px 2px 4px #666666);
              -webkit-filter: drop-shadow(2px 2px 4px #666666);
            }
          }

          .itemQuantity {
            text-align: center;
          }
        }
      };

      .subTotalPrice {
        width: 100%;
        font-weight: 700;
        text-align: end;
      };
    };
  };
`;

export default StyledOrderInfo;
