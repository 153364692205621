import styled from 'styled-components';

const StyledOrder = styled.div`
  position: relative;
  
  .content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;

    &.blur {
      filter: blur(8px);
      transition: all 0.5s;
    };

    .header {
      flex-shrink: 0;
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: #F1D7AB;

      .headerLeft {
        margin-left: 16px;
        width: 64px;

        img {
          width: 100%;
        };
      };

      .headerMiddle {
        padding: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 4px;
        flex-grow: 1;
        
        a {
          text-decoration: none;
          font-size: 20px;
          color: black;
        };
      };

      .headerRight {
        display: flex;
        flex-direction: row;
        height: 100%;

        .birdContainer {
          padding-top: 40px;
        };
      };
    };

    .body {
      height: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;

      .heroContainer {
        width: 100%;

        img  {
          width: 100%;
          object-fit: cover;

          @media (min-width: 429px) {
            display: block;
            height: 375px;
            object-position: 0px 100%;
          };
        };
      };

      form {
        display: flex;
        flex-direction: column;
        padding: 12px;

        .buffetDiv, .menuDiv {
          width: 100%;
          display: flex;
          flex-direction: column;

          .title {
            font-size: 32px;
          };

          .items {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 20px 16px;

            .item {
              width: 192px;
              height: 200px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              
              .itemImgContainer {
                position: relative;
                height: 100px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding: 16px 0px 8px 0px;
                box-sizing: border-box;
                border-radius: 12px;
                border: 3px solid black;
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                .infoIcon {
                  position: absolute;
                  top: 0px;
                  right: 0px;

                  &:hover {
                    cursor: pointer;
                  };
                };

                img {
                  width: 100%;
                  object-fit: contain;
                  filter: drop-shadow(0px 4px 2px gray);
                };

                &.selected {
                  border-color: #0171FF;
                };
              };

              .itemInfoContainer {
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                padding: 4px;
                font-size: 10px;

                .itemInfoLeft {
                  width: 60%;
                  display: flex;
                  flex-direction: column;

                  .itemName {
                    width: 100%;
                    font-size: 14px;
                  };
                };

                .itemInfoRight {
                  width: 35%;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;

                  span {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  };

                  .minus, .plus {
                    transition: all 0.2s;

                    &:hover {
                      cursor: pointer;
                    };

                    &:active {
                      transform: scale(0.8);
                      transition: all 0.2s;
                    };

                    &.disable {
                      color: lightgray;

                      &:hover {
                        cursor: default;
                      };

                      &:active {
                        transform: none;
                      };
                    };
                  };

                  .minus {
                    color: red;
                  };
                };
              };
            };
          };
        };
      };
    };

    .footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 8px 0px;
      align-items: center;
      flex-shrink: 0;
      background-color: #F1D7AB;
    };
  };
`;

export default StyledOrder;
