import { keyframes } from 'styled-components';

const fromLeft = keyframes`
  from {
    transform: translateX(50%);
  };
`;

const fromRight = keyframes`
  from {
    transform: translateX(-50%);
  };
`;

const fromTransparent = keyframes `
  from {
    opacity: 0;
  };
`;

const appear = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  };
`;


const doneIconAppear = keyframes`
  from {
    transform: scale(2);
  };
`;


const fromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export {
  fromLeft,
  fromRight,
  fromTransparent,
  appear,
  doneIconAppear,
  fromBottom,
  rotation,
};
