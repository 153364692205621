import styled from "styled-components";
import { fromLeft, fromRight, fromTransparent, appear, doneIconAppear, rotation, fromBottom } from "./animations";
import GoldenRuleMobile from '../../public/imgs/GoldenRuleMobile.png';
import FourDegreeMobile from '../../public/imgs/FourDegreeMobile.png';
import GoldenRuleDesktop from '../../public/imgs/GoldenRuleDesktop.png';
import FourDegreeDesktop from '../../public/imgs/FourDegreeDesktop.png';

const StyledHome = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: #F1D7AB;

  .homeTop {
    position: fixed;
    width: 100%;
    max-width: 1080px;
    height: min(24vw, 100px);
    background-color: #F1D7AB;
    border-bottom: 1px solid #FFFFFF;
    box-sizing: border-box;
    z-index: 1;
  };

  .navBar {
    height: min(24vw, 100px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .navLeft {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 5vw;
      gap: 6vw;
      transform: translateY(5px);

      .logoDiv {
        width: min(15vw, 60px);
      };

      .menuDiv {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: min(6vw, 24px);
        transform: translateY(min(1vw, 10px));

        .menuText {
          font-size: min(6vw, 24px);
        };

        .arrowButton {
          min-width: 12px;
          width: 6vw;
          max-width: 24px;
          border: none;
          background-color: transparent;
          transform: translateY(2px);
          padding: 0px;

          &:hover {
            cursor: pointer;
          };

          .down {
            transform: rotate(0deg);
            transition: all .5s;
          };

          .up {
            transform: rotate(180deg);
            transition: all .5s;
          };
        };
      };
    };

    .navRight {
      height: 100%;
      display: flex;
      flex-direction: row;

      .birdDiv {
        width: min(10vw, 39px);
        transform: translateY(40%);
      };

      .sunDiv {
        width: min(25vw, 104px);
      };
    };
  };

  .breakLine {
    height: min(24vw, 100px);
  };

  .menuListDiv {
    position: absolute;
    width: max(20vw, 280px);
    top: min(24vw, 100px);
    box-shadow: 8px 8px 4px 4px rgba(0, 0, 0, 0.5);

    a {
      text-decoration: none;
      color: #000000;
    };
  };

  .menuListDiv.expand {
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #F1D7AB;
    padding: 24px 48px;
    box-sizing: border-box;
    gap: 8px;

    .menuRow {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 20px;
      filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5)); /* Shadow for the img */
      text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);  /* Shadow for the text */

      .menuRowText {
        width: 100%;
        text-align: center;
      };
    };
  };

  .menuListDiv.collapse {
    display: none;
  };

  /* Animations */
  .playAnimation {
    &.treeImg {
      overflow: hidden;

      img {
        animation: ${fromRight} 1s;
      };
    };

    &.textAfterTree, &.orderTextDiv {
      animation: ${appear} 1s;
    };

    &.mountainSeaImg {
      overflow: hidden;

      img {
        animation: ${fromLeft} 1s;
      };
    };

    &.orderImgDiv {
      animation: ${fromTransparent} 2s;
    };

    &.mountainBirdImg {
      overflow: hidden;

      img {
        animation: ${fromRight} 1s;
      };
    };
  };

  .infoUpperDiv {
    animation: ${fromTransparent} 2s;
  };
  /* End of animations */

  .footer {
    height: 80px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5vw;

    div {
      width: 10vw;
      max-width: 32px;
    };
  };

  /* Mobile first*/
  @media (max-width: 428px) {
    .infoDiv {
      display: flex;
      flex-direction: column;

      .infoUpperDiv {

        img {
          width: 100%;
        }
      };

      .infoMiddleDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .treeImg {
          width: 92vw;
        };

        .textAfterTree {
          padding-right: 16px;
          color: #FFFFFF;
          font-size: 8vw;
          font-weight: 700;
          text-align: center;
        };
      };

      .infoLowerDiv {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .infoDescription {
          padding: 0px 32px;
          text-align: justify;
        };

        .mountainSeaImg {
          max-width: 100%;
          transform: translateY(5px);

          img {
            width: 100%;
          };
        };
      };
    };

    .orderDiv {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .orderUpperDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .orderLeftDiv {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;

          .orderImgDiv {
            background-image: url(${GoldenRuleMobile});
            width: 47vw;
            height: 47vw;
            background-size: cover;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
          };
        };

        .orderRightDiv {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 20px;

          .orderImgDiv {
            background-image: url(${FourDegreeMobile});
            width: 47vw;
            height: 47vw;
            background-size: cover;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
          };
        };

        .orderTextDiv {
          height: 47vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0vw 3vw;
          gap: 3vw;
          font-size: 3.5vw;
          line-height: 100%;
          text-align: center;

          .orderTextTitle {
            font-size: 6vw;
            line-height: 100%;
            font-weight: 700;
          };
        };
      };

      .orderLowerDiv {
        display: flex;
        flex-direction: column;
        gap: 12vw;

        .orderButton {
          width: 75vw;
          height: 14vw;
          border: none;
          background-color: #B91F28;
          align-self: center;
          border-radius: 8px;
          box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.5);
          padding: 0px;
          color: #FFFFFF;
          font-size: 9vw;
          font-weight: 700;
        };

        .mountainBirdImg {
          width: 35%;

          img {
            width: 100%;
          };
        };
      };
    };

    .contactDiv {
      display: flex;
      flex-direction: column;
      gap: 5vw;

      .contactUpperDiv {
        position: relative;
        display: flex;
        flex-direction: column;

        .contactTreeImgDiv {
          position: absolute;
          width: 30vw;
        };

        .contactTextDiv {
          display: flex;
          flex-direction: column;
          gap: 5vw;
          padding: 7vw 0vw;
          text-align: center;
          font-size: 3vw;

          .contactTextTitle {
            font-size: 11vw;
            font-weight: 700;
          };
        };

        .contactMapDiv {
          width: 100vw;
          height: 50vw;
          
          iframe{
            border: none;
          };
        };
      };

      .contactFormDiv {
        position: relative;

        .successIconDiv {
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #F1D7AB;

          svg {
            color: lightgreen;
            font-size: 100px;
            animation: ${doneIconAppear} 0.5s ease;
          };
        };

        .feedbackForm {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          .formTitle {
            font-size: 6vw;
            font-weight: 700;
          };

          input, textarea {
            border: none;
            width: 40vw;
            height: 24px;
            border-radius: 4px;

            &::placeholder {
              text-align: center;
              letter-spacing: 1px;
              font-size: 16px;
              color: lightgrey;
            };
          };

          input, textarea {
            width: 40vw;
            height: 24px;
          };

          textarea {
            width: 80vw;
            height: 100px;

            &::placeholder {
              text-align: start;
            };
          };

          .feedbackFormButton {
            width: 25vw;
            height: 7vw;
            border: none;
            border-radius: 4px;
            background-color: #020B5C;
            padding: 0px;
            font-size: 3vw;
            font-weight: 700;
            color: #FFFFFF;
            margin-bottom: 10px;
              
            &.submitting > div {
              width: 5vw;
              height: 5vw;
              margin: auto;
              border-radius: 50%;
              border: 2px solid #FFFFFF;
              border-color: #FFFFFF transparent #FFFFFF transparent;
              animation: ${rotation} 1s ease-out infinite;
            };

            &:disabled {
              background-color: lightgrey;
            };
          };
        };
      };
    };
  };

  /* Desktop */
  @media (min-width: 429px) {
    .infoDiv {
      display: flex;
      flex-direction: column;

      .infoUpperDiv {
        height: 35vw;
        max-height: 378px;
        overflow: hidden;

        img {
          width: 100%;
          margin-top: -51%;
        };
      };

      .infoMiddleDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;


        .textAfterTree {
          width: 70%;
          color: #FFFFFF;
          font-size: min(5vw, 56px);
          font-weight: 700;
          text-align: center;

          div:last-child {
            display: flex;
            flex-direction: row;
            justify-content: center;
          };
        };
      };

      .infoLowerDiv {
        display: flex;
        flex-direction: column;

        .infoDescription {
          margin-left: 15%;
          margin-right: 25%;
          text-align: justify;
        };

        .mountainSeaImg {
          align-self: flex-end;
        };
      };
    };

    .orderDiv {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .orderUpperDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 2%;

        .orderLeftDiv {
          width: 49%;
          display: flex;
          flex-direction: column;
          gap: min(5vw, 50px);

          .orderImgDiv {
            background-image: url(${GoldenRuleDesktop});
            background-size: cover;
            height: min(18.5vw, 200px);
            border-radius: 16px;
          };
        };

        .orderRightDiv {
          width: 49%;
          display: flex;
          flex-direction: column;
          gap: min(5vw, 50px);

          .orderImgDiv {
            background-image: url(${FourDegreeDesktop});
            background-size: cover;
            height: min(18.5vw, 200px);
            border-radius: 16px;
          };
        };

        .orderTextDiv {
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;
          text-align: center;
          
          .orderTextTitle {
            font-size: 28px;
            font-weight: 700;
          };
        };
      };

      .orderLowerDiv {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        .orderButton {
          width: min(40vw, 440px);
          height: min(8vw, 70px);
          margin-right: 10%;
          border: none;
          background-color: #B91F28;
          align-self: center;
          border-radius: 8px;
          box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.5);
          transition: all .2s;
          padding: 0px;
          color: #FFFFFF;
          font-size: min(5vw, 40px);
          font-weight: 700;

          &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
            transition: all .2s;
          };
        };

        .mountainBirdImg {
          width: 35%;

          img {
            width: 100%;
          };
        };
      };
    };

    .contactDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .contactUpperDiv {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        gap: min(2vw, 2%);

        .contactTreeImgDiv {
          display: none;
        };

        .contactTextDiv, .contactMapDiv {
          width: min(49vw, 49%);
          height: min(39.3vw, 476px);
        };

        .contactTextDiv {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          gap: 5%;

          .contactTextTitle {
            font-size: min(7vw, 40px);
            font-weight: 700;
          };

          .contactTextBody {
            display: flex;
            flex-direction: column;

            div {
              margin-top: min(2vw, 2%);
              font-size: min(1.5vw, 16px);
            };
          };
        };

        .contactMapDiv {
          iframe{
            border: none;
          };
        };
      };

      .contactFormDiv {
        width: 100%;
        position: relative;

        .successIconDiv {
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #F1D7AB;

          svg {
            color: lightgreen;
            font-size: 100px;
            animation: ${doneIconAppear} 0.5s ease;
          };
        };

        .feedbackForm {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          .formTitle {
            font-size: min(7vw, 40px);
            font-weight: 700;
          };

          input, textarea {
            border: none;
            width: 40vw;
            height: 24px;
            border-radius: 4px;

            &::placeholder {
              text-align: center;
              letter-spacing: 1px;
              font-size: 16px;
              color: lightgrey;
            };
          };

          input, textarea {
            width: 30%;
            height: 24px;
          };

          textarea {
            width: 60%;
            height: 100px;

            &::placeholder {
              text-align: start;
            };
          };

          .feedbackFormButton {
            width: min(20vw, 120px);
            height: min(6vw, 36px);
            border: none;
            border-radius: 4px;
            background-color: #020B5C;
            padding: 0px;
            font-size: min(3vw, 18px);
            font-weight: 700;
            color: #FFFFFF;
            margin-bottom: 10px;
              
            &.submitting > div {
              width: min(4vw, 24px);
              height: min(4vw, 24px);
              margin: auto;
              border-radius: 50%;
              border: 2px solid #FFFFFF;
              border-color: #FFFFFF transparent #FFFFFF transparent;
              animation: ${rotation} 1s ease-out infinite;
            };

            &:disabled {
              background-color: lightgrey;
            };
          };
        };
      };
    };
  };
`;

export default StyledHome;
