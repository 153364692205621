import styled from 'styled-components';
import { fromBottom } from './animations';

const StyledContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
  gap: 20px;

  #contactInfoTitle {
    font-size: 36px;
    padding: 20px;
  };

  #contactInfoContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    animation: ${fromBottom} .5s;

    .inputGroup {
      width: 100%;
      display: flex;
      flex-direction: column;

      label {
        font-size: 20px;
        margin: 0px 0px 2px 0px;
      };

      input {
        border: 2px #DDDDDD solid;
        border-radius: 4px;
        height: 34px;
        font-size: 14px;
        padding: 0 10px;
      
        &:required:invalid {
          border: 2px solid red;
        }
      };

      textarea {
        border: 2px #DDDDDD solid;
        border-radius: 4px;
        font-size: 14px;
        padding: 5px 10px;
        margin-bottom: 60px;
      };

      .dateGroup {
        display: flex;
        flex-direction: row;

        .rmdp-container {
          width: 50%;
          .rmdp-input {
            width: 100%;
            height: 38px;
            margin: 0px;
            box-sizing: border-box;
          }
        };

        .react-select {
          width: 25%;

          .react-select__control {
            border: 2px #DDDDDD solid;
            box-sizing: border-box;

            .react-select__value-container {
              padding: 0px 0px 0px 8px;
              width: 100%;
              height: 100%;

              .react-select__single-value {
                font-size: 14px;
                color: black;
              }

              .react-select__input-container {
                height: 100%;

                .react-select__input {
                  height: 30px;
                };
              };
            };

            .react-select__indicators {

              .react-select__indicator-separator {
                display: none;
              };

              .react-select__indicator {
                padding: 0px 8px 0px 0px;
              }
            };

            .react-select__input-container {
              padding: 0px;
              margin: 0;
            };
          };
        };
      };

      .warning {
        font-size: 20px;
        margin-top: 10px;
      }
    };
  };
`;

export default StyledContactInfo;
