import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CgCheckO } from 'react-icons/cg';
import { resetOrder, resetContact } from '../reducers';
import { submitOrder } from '../services';
import { StyledDone, StyledOrderInfo } from './styles';
import { BottomButtons, ReturnButton, ConfirmButton } from './styles/buttons';

const Items = ({ productType, items }) => (
  <div className='orderInfoItemContainer'>
    <div className='orderInfoItemTitle'>{productType === 'Menus' ? 'Menyer': productType}</div>
    <div className='orderInfoProductsItems'>
      {items.map((item) => (
        <div className='item' key={item.name}>
          <div className='itemName'>{item.name}</div>
          <div className='itemPic'>
            <img src={item.link} width='100%' />
          </div>
          <div className='itemQuantity'>x {item.quantity}</div>
        </div>
      ))}
    </div>
    <div className="subTotalPrice">
      {`Total: ca.${items.reduce((total, next) => total + next.quantity * next.refPrice, 0)} kr`}
    </div>
  </div>
);

const OrderInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { order, contact } = useSelector((state) => state);
  const [submitting, setSubmitting] = useState(false);
  const [sent, setSent] = useState(false);

  const validMenus = order.menus.filter((item) => item.quantity > 0);
  const validBuffet = order.buffet.filter((item) => item.quantity > 0);

  useEffect(() => {
    if (validMenus.length === 0 && validBuffet.length === 0) {
      navigate('/');
    };
  }, [order, contact]);

  const confirmButtonClicked = async () => {
    setSubmitting(true);
    await submitOrder(
      { menus: validMenus, buffet: validBuffet},
      contact,
    );
    setSent(true);
    setTimeout(() => {
      setSent(false);
      setSubmitting(false);
      dispatch(resetOrder());
      dispatch(resetContact());
    }, 3000);
  };

  if (sent) {
    return (
      <StyledDone>
        <div className='center'>
          <div><CgCheckO className='checkedIcon'/></div>
          <div className='thankYou'>Takk skal du ha!</div>
          <div >Du vil motta en e-post om bestillingen din.</div>
        </div>
      </StyledDone>
    )
  };

  return (
    <StyledOrderInfo>
      <div className='orderInfoContact'>
        <div>{contact.name}</div>
        <div>{contact.email}</div>
        <div>{contact.phone}</div>
        <div>{contact.date} {contact.hour}:{contact.minute}</div>
        <div>Itsudemo Sushibar</div>
        <div>Coop Mega Metro, Bibliotekgata 30, Lørenskog</div>
        <div>{contact.addition}</div>
      </div>
      <div className='orderInfoProducts'>
        {validMenus.length > 0 && <Items productType='Menus' items={validMenus} />}
        {validBuffet.length > 0 && <Items productType='Buffet' items={validBuffet} />}
        <div className='orderInfoTotal'></div>
      </div>
      <BottomButtons>
        <ReturnButton onClick={() => navigate('/contact_info')}>Tilbake</ReturnButton>
        <ConfirmButton onClick={confirmButtonClicked} disabled={submitting}>Bekrefte</ConfirmButton>
      </BottomButtons>
    </StyledOrderInfo>
  );
};

export default OrderInfo;